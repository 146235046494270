import { render, staticRenderFns } from "./tableFastAddressCategory.vue?vue&type=template&id=5e052675&scoped=true&"
import script from "./tableFastAddressCategory.vue?vue&type=script&lang=js&"
export * from "./tableFastAddressCategory.vue?vue&type=script&lang=js&"
import style0 from "./tableFastAddressCategory.vue?vue&type=style&index=0&id=5e052675&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e052675",
  null
  
)

export default component.exports
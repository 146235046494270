<template>
  <div>
    <b-row v-if="fastAddressCategories.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="fastAddressCategories"
            @row-dblclicked="editFastAddrCtgr"
            :fields="fields"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(icon)="data">
              <div class="">
                <img style="width: 25px" :src="data.item.icon" alt="" />
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  props: ["fastAddressCategories"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", thStyle: { width: "30px" } },
        { key: "name", label: "Наименование", thStyle: { width: "30px" } },
        { key: "icon", label: "Иконка", thStyle: { width: "30px" } },
        { key: "created_at", label: "Создано", thStyle: { width: "30px" } },
        {
          key: "created_by_login",
          label: "Создавший сотрудник",
          thStyle: { width: "30px" },
        },
      ],
    };
  },
  methods: {
    editFastAddrCtgr(data) {
      this.$emit("editForm", data.id);
    },
  },
  mounted() {
    resizeable();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
